import React from 'react';

import {Modal} from 'react-bootstrap';

import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {AddCourseModalContent} from 'shared/components/ProfilePageContent/AddCourseModal/AddCourseModalContent';

interface IAddCourseModal {
    enrolledCourseIds: number[];
    userId: number;
    show: boolean;
    onClose: () => void;
}

export const AddCourseModal = ({enrolledCourseIds, userId, show, onClose}: IAddCourseModal) => {
    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Courses</Modal.Title>
            </Modal.Header>
            <React.Suspense fallback={<LoadingSpinner/>}>
                <AddCourseModalContent enrolledCourseIds={enrolledCourseIds} userId={userId} onClose={onClose} />
            </React.Suspense>
        </Modal>
    );
};