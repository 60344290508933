import React from 'react';

import {Button, Container, Table} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import {IUser} from 'modules/user/models/user';
import {useTitle} from 'shared/hooks/useTitle';
import {getFullName} from 'modules/user/api/user';
import {useNavbar} from 'shared/hooks/useNavbar';
import {useGuaranteedAuth} from 'shared/hooks/useAuth';
import {useEnrolledCoursesLoadable} from 'shared/hooks/useEnrolledCoursesLoadable';

import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {LoadingError} from 'shared/components/loading/LoadingError/LoadingError';
import {EmptyMessage} from 'shared/components/EmptyMessage/EmptyMessage';
import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {ProfilePageContentRow} from 'shared/components/ProfilePageContent/ProfilePageContentRow';
import {CourseRecordTable} from 'shared/components/ProfilePageContent/CourseRecordTable/CourseRecordTable';
import {AddCourseModal} from 'shared/components/ProfilePageContent/AddCourseModal/AddCourseModal';

import './style.scss';

interface IMyProfilePageContentProps {
    user: IUser;
}

export const ProfilePageContent = ({user}: IMyProfilePageContentProps) => {
    const {t} = useTranslation();
    useNavbar(true);
    const {tokenData} = useGuaranteedAuth();

    const [showModal, setShowModal] = React.useState<boolean>(false);
    const userFullName = getFullName(user);
    useTitle(userFullName);

    const {courses, loading, error} = useEnrolledCoursesLoadable({
        userId: user.id,
        page: 0,
        limit: 100,
    });
    const isCurrentUser = user.id === tokenData.id;

    return (
        <>
            <Container className="pt-3 pb-xl">
                <h1>{userFullName}</h1>
                <ProfilePicture
                    alt={userFullName}
                    imageId={user.image?.file_id}
                    fileName={user.image?.filename}
                    round
                />
                <div className='pt-5'>
                    <h2>{t('profilePageContent.courses', 'Courses')}</h2>
                    {loading && (
                        <LoadingSpinner />
                    )}
                    {error && (
                        <LoadingError>
                            {t('profilePageContent.error', 'Error loading courses.')}
                        </LoadingError>
                    )}
                    {courses && !courses.length && (
                        <EmptyMessage>
                            {isCurrentUser ? (
                                <p>{t('profilePageContent.emptyUser', 'You are currently not enrolled in any courses.')}</p>
                            ) : (
                                <>
                                    <p>{t(
                                        'profilePageContent.emptyOther',
                                        'This student is currently not enrolled in any courses.',
                                    )}</p>
                                    <Button className='w-100 mb-3' onClick={() => setShowModal(true)}>
                                        {t('common.addCourses', 'Add Courses')}
                                    </Button>
                                </>
                            )}
                        </EmptyMessage>
                    )}
                    {courses && !!courses.length && (
                        <>
                            {isCurrentUser ? (
                                <p>{t(
                                    'profilePageContent.descriptionUser',
                                    'You are currently studying the following courses.',
                                )}</p>
                            ) : (
                                <div className='d-flex'>
                                    <p>
                                        {t(
                                            'profilePageContent.descriptionOther',
                                            'This student is currently studying the following courses.',
                                        )}
                                    </p>
                                    <Button className='ml-auto mb-3' onClick={() => setShowModal(true)}>
                                        {t('common.addCourses', 'Add Courses')}
                                    </Button>
                                </div>
                            )}
                            <Table className="border" striped={true}>
                                <thead>
                                    <tr>
                                        <th>{t('common.courseTitle', 'Course Title')}</th>
                                        <th>{t('common.courseProgress', 'Course Progress')}</th>
                                        <th className="ProfilePageContent--grade">{t('common.grade', 'Grade')}</th>
                                        <th className="ProfilePageContent--unenrol-title">{t('common.unenrol', 'Unenrol')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {courses?.map(course => (
                                        <ProfilePageContentRow userId={user.id} course={course} key={course.id} />
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </div>
                <CourseRecordTable userId={user.id} />
            </Container>
            <AddCourseModal
                enrolledCourseIds={courses ? (courses.map(course => course.id)) : []}
                userId={user.id}
                show={showModal}
                onClose={() => setShowModal(false)}
            />
        </>
    );
};
