import React from 'react';

import { Button, Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import { bulkCreateAssignments } from 'modules/assignment/api';
import { IAssignmentKey } from 'modules/assignment/models';
import { courseListSelector, ICourseListFilters } from 'modules/course/state/course-list';
import { IPaginationFilters, Pagination } from 'shared/components/Pagination/Pagination';
import { useGuaranteedAuth } from 'shared/hooks/useAuth';
import { checkAllSelector, selectedIdsStateSelector } from 'shared/state/selected-ids';
import { formatAxiosErrorOrThrow } from 'shared/utils/error';

import { Checkbox } from 'shared/components/Checkbox/Checkbox';
import { AddCourseModalRow } from 'shared/components/ProfilePageContent/AddCourseModal/AddCourseModalRow';

interface IAddCourseModalContentProps {
    enrolledCourseIds: number[];
    userId: number;
    onClose: () => void;
}

export const AddCourseModalContent = ({enrolledCourseIds, userId, onClose}: IAddCourseModalContentProps) => {
    const {tenantId} = useGuaranteedAuth();
    const {t} = useTranslation();

    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const selectorKey = 'AddCourseModal';

    const [filters, setFilters] = React.useState<ICourseListFilters>({
        tenantId,
        filters: {
            isArchived: false,
            limit: 10,
        },
        page: 0,
    });

    const [{courses, more, hasLoaded, ...rest}, setCourseList] = useRecoilState(courseListSelector(filters));

    React.useEffect(() => {
        setCourseList({courses, more, hasLoaded, ...rest});
    }, [courses, hasLoaded, more, rest, setCourseList]);
    
    const [isAllChecked, setIsAllChecked] = useRecoilState(checkAllSelector({
        tenantId,
        key: selectorKey,
    }));

    const paginationFilters: IPaginationFilters = {
        page: filters.page,
        limit: filters.filters?.limit ?? 10,
        showing: courses.length ?? 0,
    };
    const [selectedCourses, setSelectedIdState] = useRecoilState(selectedIdsStateSelector({
        tenantId,
        key: selectorKey,
    }));
    
    React.useEffect(() => {
        setSelectedIdState({
            availableIds: courses.map(course => course.id) ?? [],
            selectedIds: enrolledCourseIds,
        });
    }, [courses, enrolledCourseIds, setSelectedIdState]);

    const onSubmit = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        const newCourseIds = selectedCourses.selectedIds.flatMap((courseId) => courseId in enrolledCourseIds ? [] : [courseId]);
        const assignmentKeys: IAssignmentKey[] = newCourseIds.map(courseId => {
            return {
                course_id: courseId,
                user_id: userId,
            };
        });
        (async () => {
            try {
                await bulkCreateAssignments(tenantId, {
                    assignment_keys: assignmentKeys,
                });
                toast.success(t(
                    'AddCourseModal.success',
                    'Success. The user was enrolled in the courses.',
                ));
                onClose();
            } catch (e) {
                toast.error(formatAxiosErrorOrThrow(t(
                    'AddCourseModal.error',
                    'Error enrolling user in selected courses',
                ), e));
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Modal.Body>
            <Pagination
                filters={paginationFilters}
                isLoading={!hasLoaded && more}
                onChange={(page: number) => setFilters({...filters, page})}
            >
                <Button onClick={onSubmit} disabled={isSubmitting}>
                    {t('common.enroll', 'Enrol')}
                </Button>
                <span className="ml-2 font-weight-bold">
                    {t(
                        'AddCourseModal.selected',
                        '{{number}} Selected',
                        {number: selectedCourses.selectedIds.length},
                    )}
                </span>
            </Pagination>
            <Table bordered>
                <thead>
                    <tr>
                        <th className="table-cell--checkbox">
                            <Checkbox
                                id="UserList.all"
                                name="UserList.all"
                                checked={isAllChecked}
                                onChange={e => setIsAllChecked(e.target.checked)}
                            />
                        </th>
                        <th>{t('common.courseTitle', 'Course Title')}</th>
                    </tr>
                </thead>
                <tbody>
                    {courses.map(course => (
                        <AddCourseModalRow
                            key={course.id}
                            disabled={enrolledCourseIds.includes(course.id)}
                            course={course}
                            selectorKey={selectorKey}
                        />
                    ))}
                </tbody>
            </Table>
        </Modal.Body>
    );
};